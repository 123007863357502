import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const ComingSoon = () => {
  const {
    wp: {
      generalSettings: { title, description, siteLogoUrl },
    },
  } = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
          description
          siteLogoUrl
        }
      }
    }
  `)

  return (
    <div className='hero is-fullheight is-dark'>
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <img src={siteLogoUrl} alt={title} />
          <h1 className='has-hero-font is-size-1 has-text-primary'>{title}</h1>
          <h2 className='subtitle is-size-3'>{description}</h2>
          <h4 className='is-size-4'>Coming Soon</h4>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon
