import * as React from 'react'
import { graphql } from 'gatsby'

import IndexPage from './index'

const HomePage = props => <IndexPage {...props} />

export const query = graphql`
  query {
    wp {
      generalSettings {
        title
        description
        siteLogoUrl
      }
    }
    wpPage(slug: { eq: "home" }) {
      pageHome {
        mainContent
      }
    }
    allWpMediaItem(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Featured" } } } }
      }
    ) {
      nodes {
        id
        title
        sourceUrl
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`

export default HomePage
