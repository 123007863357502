import React, { useEffect, useState } from 'react'

const ContactForm = ({ title }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const [emailValid, setEmailValid] = useState(true)
  const [messageValid, setMessageValid] = useState(true)
  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    const validateSubmit = () => {
      let valid = false

      valid = name && email && message && emailValid && messageValid

      setCanSubmit(valid)
    }

    validateSubmit()
  }, [name, email, subject, message, emailValid, messageValid])

  const handleFormChange = (e, setValue, runValidation) => {
    e.preventDefault()
    setValue(e.target.value)

    if (runValidation) {
      runValidation(e.target.value)
    }
  }

  const validateEmail = value => {
    let valid = false

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )
    valid = pattern.test(value)

    setEmailValid(valid)
  }

  const validateMessage = value => {
    let valid = false

    valid = value && value.length > 0

    setMessageValid(valid)
  }

  return (
    <div className='box'>
      {title && <h4 className='is-size-4'>{title}</h4>}

      <form
        method='post'
        netlify-honeypot='bot-field'
        data-netlify='true'
        name='contact'
      >
        <input type='hidden' name='bot-field' />
        <input type='hidden' name='form-name' value='contact' />
        <div className='field'>
          <label className='label'>
            Name
            <div className='control has-icons-left'>
              <input
                name='name'
                type='text'
                className='input'
                placeholder='Name'
                onChange={e => handleFormChange(e, setName)}
                value={name}
              />
              <span className='icon is-small is-left'>
                <i className='fas fa-user'></i>
              </span>
            </div>
          </label>
        </div>

        <div className='field'>
          <label className='label'>
            Email
            <div className='control has-icons-left has-icons-right'>
              <input
                name='email'
                type='text'
                className={`input  ${!emailValid && 'is-danger'}`}
                placeholder='Email'
                onChange={e => handleFormChange(e, setEmail, validateEmail)}
                value={email}
              />
              <span className='icon is-small is-left'>
                <i className='fas fa-envelope'></i>
              </span>
              <span className='icon is-small is-right'>
                <i
                  className={`fas ${
                    email &&
                    (emailValid ? 'fa-check' : 'fa-exclamation-triangle')
                  }`}
                ></i>
              </span>
            </div>
            {!emailValid && (
              <p className='help is-danger'>This email is invalid</p>
            )}
          </label>
        </div>

        <div className='field'>
          <label className='label'>
            Subject
            <div className='control'>
              <div className='select'>
                <select
                  name='subject'
                  value={subject}
                  onChange={e => handleFormChange(e, setSubject)}
                >
                  <option>Order</option>
                  <option>Information</option>
                </select>
              </div>
            </div>
          </label>
        </div>

        <div className='field'>
          <label className='label'>
            Message
            <div className='control'>
              <textarea
                name='message'
                className={`textarea  ${!messageValid && 'is-danger'}`}
                placeholder='Message...'
                onChange={e => handleFormChange(e, setMessage, validateMessage)}
                value={message}
              ></textarea>
            </div>
            {!messageValid && (
              <p className='help is-danger'>Message can't be empty</p>
            )}
          </label>
        </div>

        <div className='field'>
          <div className='control'>
            <button className='button is-link' disabled={!canSubmit}>
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
