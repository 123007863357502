import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ImageCard = ({ imageFile, src, name, alt }) => {
  const image = src || getImage(imageFile)

  return (
    <div className='card'>
      <div className='card-image'>
        <div className='image is-square'>
          {image ? (
            <img src={image} alt={alt} style={{ objectFit: 'cover' }} />
          ) : (
            <GatsbyImage className='has-ratio' image={image} alt={alt} />
          )}
        </div>
      </div>
      <div className='is-overlay is-flex is-flex-direction-column is-justify-content-flex-end card-overlay'>
        <p className='is-size-5 has-text-centered has-text-light my-1'>
          {name}
        </p>
      </div>
    </div>
  )
}

export default ImageCard
