import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ContactForm from '../components/common/contact-form'
import ComingSoon from '../components/coming-soon'
import ImageCard from '../components/image-card'

const IndexPage = ({
  data: {
    wp: {
      generalSettings: { title, description, siteLogoUrl },
    },
    allWpMediaItem: { nodes: featuredCookies },
    wpPage: {
      pageHome: { mainContent: content },
    },
    wpPost: under_construction,
  },
}) => {
  if (under_construction) return <ComingSoon />

  return (
    <Layout seoTitle='Home'>
      <section className='hero is-medium'>
        <div className='hero-body is-flex is-justify-content-center is-flex-wrap-wrap'>
          <div className='mr-4'>
            <img src={siteLogoUrl} alt='logo' className='image is-128x128' />
          </div>
          <div className='has-text-centered-mobile'>
            <h1 className='has-hero-font is-size-1 has-text-primary'>
              {title}
            </h1>
            <p className='subtitle'>{description}</p>
          </div>
        </div>
      </section>
      <section className='container is-fluid py-5 has-background-dark has-text-light'>
        <div className='columns'>
          <div className='column is-three-fifths'>
            <div className='is-size-4 has-text-justified'>{content}</div>
          </div>
          <div className='column'>
            <ContactForm title='Contact Us!' />
          </div>
        </div>
      </section>
      <section className='image-row'>
        {featuredCookies.slice(0, 4).map(({ id, title, sourceUrl }) => {
          return <ImageCard key={id} src={sourceUrl} name={title} alt={title} />
        })}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    wp {
      generalSettings {
        title
        description
        siteLogoUrl
      }
    }
    wpPage(slug: { eq: "home" }) {
      pageHome {
        mainContent
      }
    }
    allWpMediaItem(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Featured" } } } }
      }
    ) {
      nodes {
        id
        title
        sourceUrl
      }
    }
    wpPost(slug: { eq: "under-construction" }) {
      id
    }
  }
`

export default IndexPage
